<template>
  <el-dialog
    v-bind="$attrs"
    :model-value="isOpen"
    :title="title"
    :width="width"
    data-testid="dialog-header"
    modal-class="nice-dialog__modal"
    @open="emit('open')"
    @close="emit('close')"
    @closed="emit('closed')"
    @update:model-value="handleUpdate"
  >
    <slot />
    <template #footer>
      <div v-if="$slots.footer" class="dialog-footer">
        <slot name="footer"></slot>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
interface NiceDialogProps {
  title: string
  width?: string
}

const emit = defineEmits(["open", "close", "closed", "update:modelValue"])

withDefaults(defineProps<NiceDialogProps>(), {
  width: "50%",
})

const handleUpdate = value => {
  isOpen.value = value
  emit("update:modelValue", value)
}

const isOpen = defineModel({ type: Boolean, default: false })
</script>

<style lang="postcss">
.nice-dialog__modal .dialog-footer {
  @apply relative flex justify-end p-0;
}
.nice-dialog__modal .el-dialog {
  margin: 5% auto 50px;
}
</style>
