<script lang="ts" setup>
import { useAttrs, onMounted, ref, computed } from "vue"
import VueDatePicker, { VueDatePickerProps } from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import { useI18n } from "vue-i18n"
import moment from "moment"
import useLocale from "@/plugins/use-locale"

const emits = defineEmits(["input", "change", "update:modelValue", "focus"])
const $attrs = useAttrs()
const { t } = useI18n()

interface DatePickerProps {
  modelValue: VueDatePickerProps["modelValue"]
  defaultTime?: string
  type?: "date" | "datetime" | "daterange" | "timerange"
  placeholder?: string
  readonly?: boolean
  clearable?: boolean
  editable?: boolean
  teleport?: boolean
  format?: string
  fixedPosition?: boolean // if nested inside other popover, apply style fixes
  selectText?: string
  autoApply?: boolean
  variant?: "default" | "text"
  allowManualInput?: boolean // can input text manually or just picker
}

const props = withDefaults(defineProps<DatePickerProps>(), {
  type: "date",
  readonly: false,
  clearable: true,
  editable: true,
  fixedPosition: false,
  selectText: "Ok",
  autoApply: true,
  variant: "default",
  allowManualInput: true,
  teleport: false,
})

const isHovered = ref(false)
const defaultTime = ref<{ hours: number; minutes: number } | undefined>(undefined)

const pickerRef = ref<typeof VueDatePicker | null>(null)

defineExpose({
  pickerRef,
})

const isRange = computed(() => props.type === "daterange" || props.type === "timerange")
const isTimePicker = computed(() => props.type === "datetime" || props.type === "timerange")
const isDate = computed(() => props.type === "date" || props.type === "daterange")

const modelType = computed(() => (isDate.value ? "yyyy-MM-dd" : null))

const TIME_FORMATS = {
  date: "dd.MM.yyyy",
  datetime: "dd.MM.yyyy HH:mm",
  daterange: "dd.MM.yyyy",
  timerange: "dd.MM.yyyy HH:mm",
}

const DEFAULT_TIME = "09:00"

// NOTE: date handling in the application is very inconsistent, and for date and daterange we need to make sure we output simple string dates like "dd.MM.yyyy"
// we need to ensure that the incoming values (date objects or iso strings) from the server and the client are transformed to the same format by discarding the time and timezone
const normalizedModelValue = computed(() => {
  if (!props.modelValue) return props.modelValue
  if (props.type === "date") return moment(props.modelValue)?.format("YYYY-MM-DD")
  if (props.type === "daterange" && Array.isArray(props.modelValue)) {
    return props.modelValue.map(v => moment(v)?.format("YYYY-MM-DD"))
  }
  return props.modelValue
})

const dateFormat = computed(() => {
  return (props.format || TIME_FORMATS[props.type]) ?? TIME_FORMATS.date
})

const handleMouseEnter = () => {
  isHovered.value = true
}

const handleMouseLeave = () => {
  isHovered.value = false
}

const handleDate = modelData => {
  emits("update:modelValue", modelData)
}

const handleChange = value => {
  emits("change", value)
}

const handleFocus = value => {
  emits("focus", value)
}
const locale = useLocale()

const setDefaultTime = () => {
  if (!props.defaultTime && props.type !== "datetime") return
  const [hours, minutes] = (props.defaultTime ?? DEFAULT_TIME).split(":").map(parseInt)
  defaultTime.value = { hours, minutes }
}

onMounted(() => {
  setDefaultTime()
})
</script>

<template>
  <VueDatePicker
    :class="['nice-datepicker-el', `variant-${variant}`, { 'fixed-position': fixedPosition }]"
    v-bind="$attrs"
    :config="{ closeOnAutoApply: false, keepActionRow: true }"
    ref="pickerRef"
    time-picker-inline
    :select-text="selectText"
    :text-input="allowManualInput ? { enterSubmit: true, tabSubmit: true } : false"
    :model-value="normalizedModelValue"
    :locale="locale"
    :format="dateFormat"
    :start-time="defaultTime"
    :action-row="{ showNow: true, showCancel: false }"
    :now-button-label="t('datePickerCurrent')"
    :range="isRange"
    :enable-time-picker="isTimePicker"
    :placeholder="placeholder"
    :disabled="readonly"
    :readonly="!editable"
    :clearable="clearable"
    :auto-apply="autoApply"
    :model-type="modelType"
    :teleport="teleport"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @change="handleChange"
    @update:model-value="handleDate"
    @focus="handleFocus"
  >
    <template #input-icon>
      <i class="ml-2 mb-1 fal fa-clock"></i>
    </template>
    <template #clear-icon="{ clear }" v-if="clearable">
      <i class="mr-2 mb-1 clear-button fal fa-times-circle" @click="clear" v-if="isHovered"></i>
    </template>
  </VueDatePicker>
</template>

<style lang="postcss">
.nice-datepicker-el.fixed-position .dp--menu-wrapper {
  z-index: 9999;
  position: fixed;
  top: auto !important;
  left: auto !important;
  margin-top: 12px;
}
.nice-datepicker-el {
  --dp-input-padding: 5px 30px 5px 12px;

  .el-date-editor.cursor-pointer > input {
    cursor: pointer;
  }

  .searchFilter-value .el-date-editor.el-input {
    width: 100%;
  }

  .dp__input {
    padding-left: 25px;
  }

  .dp__action_select {
    background-color: white !important;
    color: var(--ps-color-neutral-default) !important;
    border-color: var(--ps-color-neutral-light) !important;
    padding: 5px 12px;
  }
  .dp__action_select:hover {
    background-color: white !important;
    color: var(--el-color-primary) !important;
    border-color: var(--el-color-primary) !important;
    padding: 5px 12px;
  }

  .dp__action_cancel {
    color: var(--el-color-primary) !important;
    border: none;
  }

  .dp__active_date {
    background-color: var(--el-color-primary) !important;
    border-radius: 50%;
  }

  .dp__today {
    border-radius: 50%;
  }

  .dp__calendar_header_item {
    font-weight: 400;
  }
}

.nice-datepicker-el.variant-text {
  .dp__input {
    padding: 0;
    border: none;
  }
  .dp__input:hover {
    text-decoration: underline;
  }

  .dp__input_icon {
    display: none;
  }
}
</style>
