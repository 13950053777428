<template>
  <i class="fal" :class="'fa-' + name"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>