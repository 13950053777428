<script setup lang="ts">
import { ref } from "vue"
import { ElInput } from "element-plus"

interface NiceInputProps {
  modelValue: any
  type?: string
  maxlength?: any
  minlength?: any
  showWordLimit?: boolean
  placeholder?: string
  clearable?: boolean
  showPassword?: boolean
  disabled?: boolean
  prefixIcon?: string
  suffixIcon?: string
  rows?: number
  autosize?: any
  autocomplete?: string
  name?: string
  readonly?: boolean
  max?: any
  min?: any
  step?: string
  size?: "default" | "small" | "large"
  resize?: "none" | "vertical" | "horizontal" | "both"
  autofocus?: boolean
  form?: string
  label?: string
  tabindex?: string
  validateEvent?: boolean
}

const props = withDefaults(defineProps<NiceInputProps>(), {
  type: "text",
  showWordLimit: false,
  clearable: false,
  showPassword: false,
  disabled: false,
  rows: 0,
  readonly: false,
  autofocus: false,
  validateEvent: false,
  size: "default",
  resize: "none",
})

const input = ref<typeof ElInput | null>(null)

const emits = defineEmits(["blur", "select", "focus", "change", "clear", "update:modelValue", "enter"])

defineExpose({ input })

const isFocussed = ref(false)
const isHovered = ref(false)

const updateValue = (value: string | number | null) => {
  if (props.type === "number" && value === "") value = null
  if (props.type === "number" && value != null) value = Number(value)
  emits("update:modelValue", value)
}

const handleFocus = (event: Event) => {
  isFocussed.value = true
  emits("focus", event)
}

const handleBlur = (event: Event) => {
  isFocussed.value = false
  emits("blur", event)
}

const handleMouseEnter = () => {
  isHovered.value = true
}

const handleMouseLeave = () => {
  isHovered.value = false
}
const clear = () => {
  emits("update:modelValue", "")
}
</script>

<template>
  <el-input
    :class="[
      'nice-input',
      {
        'nice-input__maxlength-exceeded': modelValue?.length >= maxlength,
      },
    ]"
    ref="input"
    :type="type"
    :model-value="modelValue"
    :maxlength="maxlength"
    :minlength="minlength"
    :show-word-limit="showWordLimit"
    :placeholder="placeholder"
    :clearable="clearable"
    :show-password="showPassword"
    :disabled="disabled"
    :size="size"
    :prefix-icon="prefixIcon"
    :suffix-icon="suffixIcon"
    :rows="rows"
    :autosize="autosize"
    :autocomplete="autocomplete"
    :name="name"
    :readonly="readonly"
    :max="max"
    :min="min"
    :step="step"
    :resize="resize"
    :autofocus="autofocus"
    :form="form"
    :label="label"
    :tabindex="tabindex"
    :validate-event="validateEvent"
    @blur="handleBlur"
    @focus="handleFocus"
    @select="event => emits('select', event)"
    @update:model-value="updateValue"
    @clear="clear"
    @keydown.enter="event => emits('enter', event)"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <template v-slot:prefix>
      <slot v-if="$slots.prefix" name="prefix" />
    </template>
    <template v-slot:suffix>
      <i
        @click="clear"
        v-if="clearable && (isFocussed || isHovered) && modelValue"
        class="fal fa-times-circle clear-button"
      ></i>
      <slot v-if="$slots.suffix" name="suffix" />
    </template>
    <template v-if="$slots.prepend" v-slot:prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots.append" v-slot:append>
      <slot name="append" />
    </template>
  </el-input>
</template>

<style>
.nice-input .el-input__count .el-input__count-inner {
  background: transparent;
  margin-right: 2px;
}

.el-input__inner {
  padding-left: 12px;
  padding-right: 12px;
}
.el-input__wrapper {
  padding: 0;
}
.el-input--small .el-input__wrapper {
  padding: 0;
}
.el-input--small {
  font-size: var(--ps-font-size-md);
}
.el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
}
.nice-input__maxlength-exceeded {
  .el-input__count-inner {
    color: var(--ps-color-danger);
  }
}
</style>
